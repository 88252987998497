<template lang="pug">
.page
  .card(:class='{ isMobile }')
    .logo
      img(src='https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/common-asset/icon/logo.png')
    template(v-if="isLoginByMiniapp")
      .oAuthStatus 微信小程序授权登录处理中....
    template(v-else-if="isDebug")
      el-form.form
        el-form-item
          el-input(v-model:value='formData.id' placeholder="id")
        el-form-item
          el-button(style="width:100%" type='warning', @click='handleLoginByDebug') 登录(Debug)
    template(v-else)
      el-form.form
        el-form-item
          el-input(v-model='formData.phone' placeholder="手机号")
        el-form-item
          el-input(v-model='formData.sms' placeholder="验证码" style="width:200px;margin-right:10px")
          el-button(type='success' @click='handleGetSms' v-if="smsTime<=0") 获取验证码
          el-button(type='success' v-else disabled) {{smsTime}}秒后可重发
        el-form-item
          el-button(style="width:100%" type='primary', @click='handleLoginBySms') 登录

</template>

<script>
export default {
  layout: 'fullPage'
}
</script>
<script setup>
import { inject, onMounted, ref, computed } from 'vue'
import { useRoute } from 'vue-router'

import { handleLoginByAccount } from '@/provider/account'
import { isMobile } from '@/provider/sys'
import { updateCache } from '@/provider/cache'

const { message, router, fetch } = inject('global')

const route = useRoute()

const formData = ref({
  id: '123',
  phone: '',
  sms: ''
})

const smsTime = ref(0)
let timer = null

const isDebug = computed(() => {
  return route.query.debug
})

function timeGo() {
  smsTime.value = 59
  timer = setInterval(() => {
    smsTime.value--
    if (smsTime.value <= 0) {
      clearInterval(timer)
    }
  }, 1000)
}

function afterLoginSuccess(res) {
  localStorage.token = res.token
  updateCache('accountInfo', res.accountInfo, { expireTime: 0 })
  router.push('/')
}
const handleLoginByDebug = () => {
  if (!formData.value.id) {
    return
  } else {
    afterLoginSuccess({
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJ5S3Z1U1VJc3BpIiwiYWNjb3VudElkIjoieUt2dVNVSXNwaSIsInRydWVOYW1lIjoi5oiR5piv566h55CG5ZGYIiwidGVuYW50SWQiOjEsIm1hbGxJZCI6IjEzMzcwMDY2NDQ0MzE3MTIyNTciLCJpYXQiOjE2Mzc3MjE0MzMsImV4cCI6MTY2OTI3OTAzM30.47dumkV_DV6eurrJF4MSOcJgm-q6cZMIsg3xWnutIdQ',
      accountInfo: {
        id: 1111,
        userName: '12312321'
      }
    })
  }
}
function handleGetSms() {
  fetch
    .get('/boom-center-admin-service/app/commercialPlazaUserInfo/getVerificationCode', {
      params: { phoneNumber: formData.value.phone }
    })
    .then(res => {
      message.success('发送成功，请留意信息')
      timeGo()
    })
    .catch(err => {
      message.error(err.msg || err)
    })
}
function handleLoginBySms() {
  fetch
    .post('/boom-center-admin-service/app/commercialPlazaUserInfo/loginByPhoneNumber', {
      phoneNumber: formData.value.phone,
      verificationCode: formData.value.sms
    })
    .then(res => {
      if (res.accessToken) {
        localStorage['X-Commercial-Access-Token'] = res.accessToken
        router.replace('/account/pick')
      }
    })
    .catch(err => {
      message.error(err.msg || err)
    })
}

// 小程序跳转登录
function handleLoginByCode(code) {
  localStorage['Bm-Member-Token'] = code
  fetch
    .post('/boom-center-admin-service/app/commercialPlazaUserInfo/loginByMemberToken')
    .then(res => {
      if (res.accessToken) {
        localStorage['X-Commercial-Access-Token'] = res.accessToken
        router.replace('/account/pick')
      }
    })
    .catch(err => {
      message.error(err.msg || err)
      isLoginByMiniapp.value = false
    })
}

const isLoginByMiniapp = ref(false)

onMounted(() => {
  isLoginByMiniapp.value = !!route?.query?.code
  if (isLoginByMiniapp.value) {
    handleLoginByCode(route.query.code)
  }
})
</script>

<style lang="less" scoped>
.page {
  height: 100vh;
  width: 100vw;
  background: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rainbowColor 15s linear infinite;
}
.card {
  background: white;
  border-radius: 10px;
  z-index: 1000;
  box-shadow: 1px 2px 10px 6px rgba(255, 255, 255, 0.5);
  display: flex;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 200px;
    }
    padding: 60px;
  }
  .form {
    padding: 0 20px;
  }
  .action {
    padding: 20px;
  }
}

.oAuthStatus {
  display: flex;
  padding: 30px 0;
  justify-content: center;
}

@keyframes rainbowColor {
  0% {
    background: #f44336;
  }
  5% {
    background: #e91e63;
  }
  10% {
    background: #9c27b0;
  }
  15% {
    background: #673ab7;
  }
  20% {
    background: #3f51b5;
  }
  25% {
    background: #2196f3;
  }
  30% {
    background: #03a9f4;
  }
  40% {
    background: #00bcd4;
  }
  50% {
    background: #009688;
  }
  60% {
    background: #4caf50;
  }
  70% {
    background: #8bc34a;
  }
  80% {
    background: #cddc39;
  }
  90% {
    background: #ffc107;
  }
  95% {
    background: #ff9800;
  }
  100% {
    background: #ff5722;
  }
}
</style>
